export const IMAGES: Record<string, string> = {
  companyLogo: require('../assets/img/company-logo.svg').default,
  productLogo: require('../assets/img/product-logo.svg').default,
  mainPage: require('../assets/img/main-page.jpg').default,
  bannerResultMobile: require('../assets/img/banner-result-mobile.png').default,
  bannerResultLandscape: require('../assets/img/banner-result-landscape.png')
    .default,
  bannerResultDesktop: require('../assets/img/banner-result-desktop.png')
    .default,
  concernImage: require('../assets/img/concern.png').default,
  controlImage: require('../assets/img/control.png').default,
  curiosityImage: require('../assets/img/curiosity.png').default,
  confidenceImage: require('../assets/img/confidence.png').default,
  cooperationImage: require('../assets/img/cooperation.png').default,
  concernBodyImage: require('../assets/img/concern-body.svg').default,
  controlBodyImage: require('../assets/img/control-body.svg').default,
  curiosityBodyImage: require('../assets/img/curiosity-body.svg').default,
  confidenceBodyImage: require('../assets/img/confidence-body.svg').default,
  cooperationBodyImage: require('../assets/img/cooperation-body.svg').default,
  starLow: require('../assets/img/star-low.svg').default,
  starModerate: require('../assets/img/star-moderate.svg').default,
  starHigh: require('../assets/img/star-high.svg').default,
  superPower: require('../assets/img/superpower-icon.svg').default,
  close: require('../assets/img/close.svg').default,
  circleBackdrop: require('../assets/img/circle-backdrop.png').default,
  starTopBackdrop: require('../assets/img/star-backdrop.png').default,
  starBottomBackdrop: require('../assets/img/star-bottom-backdrop.png').default,
  ovalBackdrop: require('../assets/img/oval-backdrop.png').default,
  home: require('../assets/img/section-home.svg').default,
  assessment: require('../assets/img/section-assessment.svg').default,
  users: require('../assets/img/section-users.svg').default,
  userProfile: require('../assets/img/user.png').default,
  CareerFlex: require('../assets/img/career-flex.png').default,
  ['CareerFlex+']: require('../assets/img/career-flex.png').default,
  ['Career Design Game']: require('../assets/img/career-design-game.png')
    .default,
  ['My Career Adventure']: require('../assets/img/my-career-adventure.png')
    .default,
  ['Career Design Canvas']: require('../assets/img/career-design-canvas.png')
    .default,
  word: require('../assets/img/word-icon.png').default,
  logic: require('../assets/img/logic-icon.png').default,
  self: require('../assets/img/self-icon.png').default,
  people: require('../assets/img/people-icon.png').default,
  music: require('../assets/img/music-icon.png').default,
  body: require('../assets/img/body-icon.png').default,
  picture: require('../assets/img/picture-icon.png').default,
  nature: require('../assets/img/nature-icon.png').default,
  'Technical Competence': require('../assets/img/technical-icon.png').default,
  'Managerial Competence': require('../assets/img/managerial-icon.png').default,
  Autonomy: require('../assets/img/autonomy-icon.png').default,
  'Job Security': require('../assets/img/job-security-icon.png').default,
  Lifestyle: require('../assets/img/creativity-icon.png').default,
  Entrepreneurial: require('../assets/img/entrepreneurial-icon.png').default,
  'Service/ dedication to a cause': require('../assets/img/service-icon.png')
    .default,
  'Pure Challenge': require('../assets/img/pure-challenge-icon.png').default,
  avidAdventures: require('../assets/img/avid-adventures-logo.svg').default,
  facebook: require('../assets/img/facebook-icon.svg').default,
  ['CareerFlexBetterQuality']:
    require('../assets/img/careerFlexBetterQuality.png').default,
  ['CareerDesignGameBetterQuality']:
    require('../assets/img/сareer-design-game-better-quality.png').default,
  ['CareerDesignCanvasBetterQuality']:
    require('../assets/img/career-design-canvas-better-quality.png').default,
  ['MyCareerAdventureBetterQuality']:
    require('../assets/img/career-adventury-better-quality.png').default,
  emailFooter: require('../assets/img/email-footer.png').default,
  canvasMedium: require('../assets/img/canvas-title-medium.png').default,
  backdropDesign: require('../assets/img/backdrop-design-game.png').default,
  twoMobiles: require('../assets/img/two-mobile-phone.png').default,
  careerFlexSlide: require('../assets/img/career-flex-slide.png').default,
  canvasSlide: require('../assets/img/canvas-slide.png').default,
  canvasGif: require('../assets/img/canvas-slide.gif').default,
  phonesMain: require('../assets/img/phones-main.png').default,
  girlMain: require('../assets/img/girl-main.png').default,
  phoneFlex: require('../assets/img/phoneFlex-main.png').default,
};
