import React, { FC, useMemo } from 'react';

import { Button } from '@components/button';
import { Loader } from '@components/loader';
import { MyCareerAnchors } from './components/my-career-anchors';
import { MyHollandCode } from './components/my-holland-code';
import { MyIdealEnvironment } from './components/my-ideal-environment';
import { MyMBTI } from './components/my-MBTI';
import { MyValues } from './components/my-values';

import { PROMISES_AREA } from '@constants/promises-area';
import { QUESTION_SECTIONS } from '../../canvas-quiz-page.constants';

import { IFitProps } from './fit.typings';

import { HeaderSectionStyled } from '../header-section.styles';
import { FitStyled as Styled } from './fit.styles';
import { CATEGORY_HEADINGS } from '@constants/career-canvas';
import { CanvasCategoryHeading } from '@components/canvas-category-heading';

const [label, color] = CATEGORY_HEADINGS.fit;

export const Fit: FC<IFitProps> = (props) => {
  const { questionListForSection, onSubmitSection, onChangeAnswer } = props;

  const questionListForCategories = useMemo(() => {
    const categoriesHashMaps: Record<string, IQuestionListItem[]> = {
      myValues: [],
      myCareerAnchors: [],
      myMBTI: [],
      myIdealEnvironment: [],
      myHollandCode: [],
    };
    questionListForSection
      .sort((first, second) => first.order - second.order)
      .forEach((question) => {
        const category = categoriesHashMaps[question.category];
        category.push(question);
      });
    return categoriesHashMaps;
  }, [questionListForSection]);

  return (
    <div>
      <HeaderSectionStyled.TitleWrapper>
        <h1>My VALUES</h1>
        <CanvasCategoryHeading label={label} color={color} />
      </HeaderSectionStyled.TitleWrapper>
      <MyValues
        questionList={questionListForCategories['myValues']}
        onChangeAnswer={onChangeAnswer}
      />
      <MyCareerAnchors
        questionList={questionListForCategories['myCareerAnchors']}
        onChangeAnswer={onChangeAnswer}
      />
      <HeaderSectionStyled.TitleWrapper>
        <h1>My PERSONALITY</h1>
      </HeaderSectionStyled.TitleWrapper>
      <MyMBTI
        questionList={questionListForCategories['myMBTI']}
        onChangeAnswer={onChangeAnswer}
      />
      <MyHollandCode
        questionList={questionListForCategories['myHollandCode']}
        onChangeAnswer={onChangeAnswer}
      />
      <HeaderSectionStyled.TitleWrapper>
        <h1>MY IDEAL ENVIRONMENT</h1>
      </HeaderSectionStyled.TitleWrapper>
      <MyIdealEnvironment
        questionList={questionListForCategories['myIdealEnvironment']}
        onChangeAnswer={onChangeAnswer}
      />
      <Styled.Control>
        <Loader area={PROMISES_AREA.sendCanvasAnswers}>
          <Button
            title="Save & Next Section"
            borderRadius="8px"
            color={QUESTION_SECTIONS.FIT.color}
            onClick={onSubmitSection}
          />
        </Loader>
      </Styled.Control>
    </div>
  );
};
