export const MANAGE_TRAINERS = {
  filterOptions: [
    { name: 'Name' },
    { email: 'Email' },
    { organization: 'School/Organization' },
    { created: 'Registered On' },
    // 'No. of Games',
    // 'Subscription Expiration',
    // 'Payment',
    // 'Accreditation',
  ],
};
