import styled from 'styled-components';

import { COLORS } from '@styles/colors';
import { Media } from '@styles/media';
import { FONTS } from '@styles/fonts';

export interface IBgContainerProps {
  isGreyBg?: boolean;
  isFullHeight?: boolean;
  isWithPadding?: boolean;
}

export const ResultPageStyled = {
  Root: styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: ${({ width }) => (width ? width : '333px')};

    ${Media.smallLandscape`
      max-width: 100%;
    `}
  `,
  Header: styled.div<{ isCentred?: boolean }>`
    display: flex;
    align-items: center;

    ${({ isCentred }) => isCentred && 'justify-content: center'};

    > *:not(:first-child) {
      margin-left: 16px;
    }
  `,
  LogoContainer: styled.div`
    font-family: ${FONTS.family.absideSmooth};
    font-size: ${FONTS.sizes[22]};
    color: ${COLORS.grey};
    letter-spacing: -1px;
  `,

  BgContainer: styled.div<IBgContainerProps>`
    display: flex;
    flex-direction: column;

    ${({ isWithPadding = true }) => isWithPadding && 'padding: 8px 16px'};
    ${({ isFullHeight }) => isFullHeight && 'flex: 1'};
    ${({ isGreyBg }) => isGreyBg && `background-color:${COLORS.greyResult}`}
  `,
};
