import styled, { css } from 'styled-components';

import { COLORS } from '@styles/colors';
import { FONTS } from '@styles/fonts';
import { Media } from '@styles/media';

export const HeaderSectionStyled = {
  TitleWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    h1 {
      font-family: ${FONTS.family.absideSmooth};
      font-size: ${FONTS.sizes[30]};
      color: ${COLORS.grey};
      font-weight: 400;

      ${Media.tablet(css`
        font-size: ${FONTS.sizes[26]};
      `)};

      ${Media.mobile(css`
        font-size: ${FONTS.sizes[22]};
      `)};
    }
  `,
};
