import { IMAGES } from '@constants/images';

import { ResultText } from '../common';
import { IResultPageProps } from '../../canvas-results.typing';

import { PracticalityResultStyled as Styled } from './practicality-result.styles';

export const PracticalityResult = (props: IResultPageProps) => {
  const { questions } = props;

  return (
    <Styled.Root width="290px" header={{ title: 'PRACTICALITY CHECK' }}>
      <Styled.AnswersContainer>
        {questions.map(({ title, value }) => (
          <ResultText
            key={title}
            title={title}
            value={value}
            height="122px"
            isYellow
          />
        ))}
      </Styled.AnswersContainer>

      <Styled.LogoContainer>
        <Styled.LogoCenter>
          <img src={IMAGES.avidAdventures} />

          <Styled.Info>
            This Career Design Canvas draws on decades of research from social
            scientists and career development practitioners. It is best
            complemented with the Career Design Life Game and other tools found
            on www.witgritfit.com
          </Styled.Info>
        </Styled.LogoCenter>
      </Styled.LogoContainer>
    </Styled.Root>
  );
};
