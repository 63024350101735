import { SKILLS_ORDER } from '@constants/career-canvas';
import { IResultQuestion } from 'typings/career-canvas';

import { getPositiveOrder } from './array';

export const getTopHollandCodeTitles = (questionResult: IResultQuestion[]) =>
  [...questionResult]
    .sort((first, second) => +second.value - +first.value)
    .slice(0, 3)
    .map((question) => question.title);

export const sortSkillsAnswerOptionsCb = (first: string, second: string) =>
  getPositiveOrder(SKILLS_ORDER, first) -
  getPositiveOrder(SKILLS_ORDER, second);
