export const MANAGE_USERS = {
  filterOptions: [
    'Name',
    'Group name',
    'Registered on',
    'Email',
    'Career flex',
    'Career flex cooperation',
    'Career design game',
    'Career design canvas',
    'My career adventure',
  ],
};
