import { COLORS } from '@styles/colors';

import { IInitialQuestionsState } from './canvas-quiz-page.typings';

export const QUESTION_SECTIONS = {
  WIT: { title: 'WIT', color: COLORS.greenLight },
  GRIT: {
    title: 'GRIT',
    color: COLORS.lightBlue,
  },
  FIT: {
    title: 'FIT',
    color: COLORS.pink,
  },
  'MY SKILLS': {
    title: 'MY SKILLS',
    color: COLORS.default,
  },
  'PRACTICALITY CHECK': {
    title: 'PRACTICALITY CHECK',
    color: COLORS.violetLight,
  },
  'NEXT STEPS': {
    title: 'NEXT STEPS',
    color: COLORS.yellow,
  },
};

export const initialQuestionsState: IInitialQuestionsState = {
  questionList: [],
};

export const categoriesListForSection: Record<string, string[]> = {
  WIT: ['mySmarts'],
  GRIT: ['myPerformanceCharacter'],
  FIT: [
    'myValues',
    'myCareerAnchors',
    'myMBTI',
    'myHollandCode',
    'myIdealEnvironment',
  ],
  'MY SKILLS': ['coreCriticalSkills', 'technicalSkills'],
  'PRACTICALITY CHECK': ['practicalityCheck'],
  'NEXT STEPS': ['nextSteps'],
};
