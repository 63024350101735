import { FC } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { COLORS } from '@styles/colors';
import { FONTS } from '@styles/fonts';

interface IProps {
  title: string;
  value: string;
  height?: string;
  isYellow?: boolean;
  className?: string;
  answerCss?: FlattenSimpleInterpolation;
}

const Answer = styled.div<{
  height?: string;
  isYellow?: boolean;
  styledCss?: FlattenSimpleInterpolation;
}>`
  height: ${({ height }) => height || '60px'};
  min-height: 50px;
  margin-top: 1.5px;
  padding: 5px;
  border: 1px solid ${COLORS.dark};
  border-radius: 5px;
  color: ${COLORS.dark};
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isYellow }) =>
    isYellow &&
    css`
      background-color: ${COLORS.yellowLight};
      border-width: 0px;
      color: inherit;
    `}

  ${({ styledCss }) => styledCss};
`;

const Title = styled.div`
  @media print {
    font-family: ${FONTS.family.frutigerBold};
    color: ${COLORS.default};
  }
`;

export const ResultText: FC<IProps> = (props) => {
  const { title, height, value, isYellow, answerCss, children, className } =
    props;

  return (
    <div className={className}>
      <Title>{title}</Title>
      <Answer isYellow={isYellow} height={height} styledCss={answerCss}>
        {value}
      </Answer>

      {children}
    </div>
  );
};
