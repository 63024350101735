import { IUserRadioList } from './pop-up.typings';

export const userRadioGroup: IUserRadioList[] = [
  {
    label: 'Student',
    value: 'Student',
  },
  {
    label: 'Working Professional',
    value: 'Working Professional',
  },
];
