import { CanvasCategoryHeading } from '@components/canvas-category-heading';
import { FC } from 'react';

import { CanvasResultsStyled } from '../styled';

import {
  IBgContainerProps,
  ResultPageStyled as Styled,
} from './result-page.styles';

interface IHeaderProps {
  heading?: string;
  headingColor?: string;
  logo?: string;
  title?: string;
  isCentred?: boolean;
}

interface IProps extends IBgContainerProps {
  header: IHeaderProps;
  width?: string;
  className?: string;
}

export const ResultPage: FC<IProps> = (props) => {
  const { width, header, children, className, ...bgContainerProps } = props;
  const { heading, logo, title, headingColor, isCentred } = header;

  return (
    <Styled.Root width={width} className={className}>
      <Styled.BgContainer {...bgContainerProps}>
        <Styled.Header isCentred={isCentred}>
          {heading && (
            <CanvasCategoryHeading label={heading} color={headingColor} />
          )}
          {logo && <Styled.LogoContainer>{logo}</Styled.LogoContainer>}
          {title && (
            <CanvasResultsStyled.PageTitle>
              {title}
            </CanvasResultsStyled.PageTitle>
          )}
        </Styled.Header>

        {children}
      </Styled.BgContainer>
    </Styled.Root>
  );
};
