import styled, { css } from 'styled-components';

import { Media } from '@styles/media';
import { COLORS } from '@styles/colors';
import { IResultQuestion } from 'typings/career-canvas';

import { ResultText } from '../common';
import { FONTS } from '@styles/fonts';

interface IProps {
  results: IResultQuestion[];
  date: string;
}

const DATE_INDEX = 1;

const Styled = {
  Root: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 12px 24px;

    ${Media.smallLandscape`
      flex-direction: column;`}
  `,
  Result: styled(ResultText)<{ isWithDate?: boolean }>`
    flex: 1;

    ${({ isWithDate }) => isWithDate && 'position: relative'};

    &:not(:first-child) {
      margin-left: 32px;

      ${Media.smallLandscape`
        margin-left: 0;
        margin-top: 32px;
      `}
    }
  `,
  ResultTitleCss: css`
    color: ${COLORS.blackGray};
  `,
  ResultAnswerCss: css`
    height: 72px;
    border: 1px solid #f0f0f0;
  `,
  Date: styled.p`
    position: absolute;
    top: 0;
    right: 4px;
    font-family: ${FONTS.family.frutigerNormal};
    font-size: ${FONTS.sizes[11]};
  `,
};

export const NextStepsResult = (props: IProps) => {
  const { results, date } = props;

  const RenderResults = results.map((result, index) => (
    <Styled.Result
      key={result.title}
      title={result.title}
      value={result.value}
      answerCss={Styled.ResultAnswerCss}
      isWithDate={DATE_INDEX === index}
    >
      {DATE_INDEX === index && <Styled.Date>Date: {date}</Styled.Date>}
    </Styled.Result>
  ));

  return <Styled.Root>{RenderResults}</Styled.Root>;
};
