import { ROUTES } from '@constants/routes';

interface IRedirectToUrlParams {
  url: string;
  email: string;
  name: string;
}

export const redirectAuth = (params: IRedirectToUrlParams) => {
  const { url, email, name } = params;

  const emailParam = `email=${encodeURIComponent(email)}`;
  const nameParam = `&name=${encodeURIComponent(name)}`;

  window.location.replace(`${url}?${emailParam}${nameParam}`);
};

export const authStudentGameLink = `${ROUTES.redirectAuth}?redirect=${process.env.REACT_APP_GAME}/log-in`;

export const getPreviousAuthUrl = (locationFrom: ILocationState['from']) => {
  const { pathname, search } = locationFrom;
  return pathname + search;
};
