import styled from 'styled-components';

import { FONTS } from '@styles/fonts';

import { BrightShadowWrapper } from '../bright-shadow-wrapper';

export const HollandResultStyled = {
  RangesWrapper: styled.div`
    margin-top: 8px;
  `,
  HighestScores: styled.div`
    margin-top: 16px;
    display: flex;
    font-size: ${FONTS.sizes[14]};
    justify-content: center;
    align-items: center;
  `,
  LettersWrapper: styled.div`
    display: flex;
    margin-left: 14px;
  `,
  LetterWrapper: styled(BrightShadowWrapper)`
    :not(:first-child) {
      margin-left: 8px;
    }
  `,
};
