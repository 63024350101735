import { HOLLAND_CODE_LETTER_COLORS } from '@constants/career-canvas';
import { COLORS } from '@styles/colors';
import { IResultQuestion } from 'typings/career-canvas';

import { CanvasResultsStyled, ResultRange } from '../common';

import { HollandResultStyled as Styled } from './holland-code-result.styled';
import { getTopHollandCodeTitles } from '@services/utils';

interface IProps {
  questions: IResultQuestion[];
}

export const HollandCodeResult = (props: IProps) => {
  const { questions } = props;

  const topTitles = getTopHollandCodeTitles(questions);

  const RenderTopLetters = topTitles.map((title) => (
    <Styled.LetterWrapper key={title} color={HOLLAND_CODE_LETTER_COLORS[title]}>
      {title.slice(0, 1)}
    </Styled.LetterWrapper>
  ));

  return (
    <div>
      <p>My Holland Code</p>

      <Styled.RangesWrapper>
        <CanvasResultsStyled.RangesGrid columnGap="50px">
          {questions.map(({ title, value, color }) => (
            <ResultRange
              isTitlePrimary={false}
              titleFirstLetterColor={HOLLAND_CODE_LETTER_COLORS[title]}
              key={title}
              title={title}
              value={+value}
              maxValue={6}
              color={color || COLORS.lightBlue}
              circleSize={16}
            />
          ))}
        </CanvasResultsStyled.RangesGrid>
      </Styled.RangesWrapper>

      <Styled.HighestScores>
        My highest scores:{' '}
        <Styled.LettersWrapper>{RenderTopLetters}</Styled.LettersWrapper>
      </Styled.HighestScores>
    </div>
  );
};
