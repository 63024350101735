import { IResultProgressProps, ResultProgress } from './progress';

import { Styled } from './result-range.styles';

interface IResultRangeTitleProps {
  title: string;
  isPrimary: boolean;
  color: string;
  firstLetterColor?: string;
}

interface IIResultRangeColoredTitleProps {
  title: string;
  firstLetterColor: string;
}

interface IResultRangeProps extends IResultProgressProps {
  title: string;
  isTitlePrimary?: boolean;
  titleFirstLetterColor?: string;
}

const ResultRangeColoredTitle = (props: IIResultRangeColoredTitleProps) => {
  const { title, firstLetterColor } = props;

  const firstLetter = title.slice(0, 1);
  const otherLetters = title.slice(1);

  return (
    <Styled.SecondaryTitle>
      <Styled.SecondaryTitleColored color={firstLetterColor}>
        {firstLetter}
      </Styled.SecondaryTitleColored>
      {otherLetters}
    </Styled.SecondaryTitle>
  );
};

const ResultRangeTitle = (props: IResultRangeTitleProps) => {
  const { title, isPrimary, color, firstLetterColor } = props;

  if (isPrimary || !firstLetterColor) {
    return <Styled.Title color={color}>{title}</Styled.Title>;
  }

  return (
    <ResultRangeColoredTitle
      title={title}
      firstLetterColor={firstLetterColor}
    />
  );
};

export const ResultRange = (props: IResultRangeProps) => {
  const {
    title,
    color,
    isTitlePrimary = true,
    titleFirstLetterColor,
    ...progressProps
  } = props;

  return (
    <>
      <ResultRangeTitle
        title={title}
        isPrimary={isTitlePrimary}
        color={color}
        firstLetterColor={titleFirstLetterColor}
      />

      <Styled.ProgressContainer>
        <ResultProgress color={color} {...progressProps} />
      </Styled.ProgressContainer>
    </>
  );
};
