import styled from 'styled-components';

import { COLORS } from '@styles/colors';
import { FONTS } from '@styles/fonts';
import { ResultPage } from '../common';
import { Media } from '@styles/media';

export const PracticalityResultStyled = {
  Root: styled(ResultPage)`
    position: relative;
  `,
  AnswersContainer: styled.div`
    flex-grow: 1;
    margin-top: 4px;

    > * + * {
      margin-top: 15px;
    }
  `,
  LogoContainer: styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 25px 20px;
    background-color: ${COLORS.greyResult};

    ${Media.smallLandscape`
      position: static;
      margin-top: 30px;
    `};
  `,
  LogoCenter: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Info: styled.p`
    margin-top: 32px;
    color: ${COLORS.black};
    font-family: ${FONTS.family.frutigerNormal};
    font-size: ${FONTS.sizes[11]};
  `,
};
