import styled from 'styled-components';

import { COLORS } from '@styles/colors';
import { FONTS } from '@styles/fonts';

export const Styled = {
  Title: styled.b<{ color: string }>`
    text-transform: uppercase;
    font-size: ${FONTS.sizes[16]};
    font-family: ${FONTS.family.frutigerBold};
    color: ${({ color }) => color};

    @media print {
      color: ${COLORS.default};
    }
  `,
  SecondaryTitle: styled.span`
    font-size: ${FONTS.sizes[16]};
    font-family: ${FONTS.family.frutigerNormal};

    @media print {
      color: ${COLORS.default};
    }
  `,
  SecondaryTitleColored: styled.span<{ color?: string }>`
    color: ${({ color = COLORS.default }) => color};
  `,
  ImageContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 26px;
    height: 19px;
  `,
  ProgressContainer: styled.div`
    flex: 1;
  `,
};
