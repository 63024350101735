import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAppSelector } from '@services/hooks/redux';
import { redirectAuth } from '@services/utils';

export const RedirectToGame: FC<RouteComponentProps> = (props) => {
  const { search } = props.location;
  const url = new URLSearchParams(search).get('redirect');

  const user = useAppSelector((state) => state.user);

  const fullName = `${user.firstName} ${user.lastName}`;

  if (url) {
    redirectAuth({ url, email: user.email, name: fullName });
  }

  return null;
};
