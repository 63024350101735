export const ENDPOINTS = {
  user: 'user',
  quiz: 'quiz',
  answers: 'answers',
  pdf: 'pdf',
  group: 'group',
  invitation: 'invitation',
  facebook: 'facebook',
  google: 'google',
  verification: 'verification',
  game: 'game',
};
