import styled from 'styled-components';

import { COLORS } from '@styles/colors';
import { FONTS } from '@styles/fonts';

export const BrightShadowWrapper = styled.div<{
  color: string;
  shadowColor?: string;
}>`
  width: 25px;
  height: 23px;
  line-height: 23px;
  border-radius: 6px;
  text-align: center;
  overflow: hidden;
  background-color: ${COLORS.white};
  font-family: ${FONTS.family.absideSmooth};
  font-size: ${FONTS.sizes[18]};

  box-shadow: 1px 1px 4px ${({ color, shadowColor }) => shadowColor || color};
  color: ${({ color }) => color};
`;
