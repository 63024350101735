import { IMAGES } from '@constants/images';

import {
  IImportanceProgressProps,
  ImportanceProgress,
} from './importance-progress';
import { ResultImportanceStyled as Styled } from './result-importance.styles';

interface IProps extends IImportanceProgressProps {
  title: string;
}

export const ResultImportance = (props: IProps) => {
  const { title, ...progressProps } = props;

  return (
    <Styled.Root>
      <Styled.Title>{title}</Styled.Title>

      <Styled.ProgressContainer>
        <ImportanceProgress {...progressProps} />
      </Styled.ProgressContainer>
    </Styled.Root>
  );
};
