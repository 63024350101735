import styled from 'styled-components';

import { COLORS } from '@styles/colors';
import { FONTS } from '@styles/fonts';

export const CanvasCategoryHeadingStyled = {
  ShadowWrapper: styled.div`
    width: 108px;
    height: 43px;
    align-self: flex-start;
    flex-shrink: 0;

    filter: drop-shadow(
        0px 5.108269691467285px 2.3144733905792236px rgba(0, 0, 0, 0.02)
      )
      drop-shadow(
        0px 10.995718955993652px 6.519492149353027px rgba(0, 0, 0, 0.03)
      )
      drop-shadow(
        0px 18.450008392333984px 13.731372833251953px rgba(0, 0, 0, 0.04)
      )
      drop-shadow(
        0px 29.27069854736328px 26.14143943786621px rgba(0, 0, 0, 0.04)
      )
      drop-shadow(
        0px 48.65275192260742px 46.20719909667969px rgba(0, 0, 0, 0.05)
      )
      drop-shadow(0px 100px 67px rgba(0, 0, 0, 0.07));
  `,
  FigureWrapper: styled.div`
    width: 108px;
    height: 43px;
    align-self: flex-start;
    flex-shrink: 0;
    padding: 1px;
    clip-path: polygon(0 0, 90% 0, 100% 25%, 100% 100%, 10% 100%, 0 75%);
    background-color: ${COLORS.sunglow};
  `,
  Content: styled.div<{ color?: string }>`
    width: 100%;
    height: 100%;
    line-height: 43px;
    background-color: ${COLORS.titanWhite};
    mix-blend-mode: normal;
    clip-path: polygon(0 0, 90% 0, 100% 25%, 100% 100%, 10% 100%, 0 75%);
    text-align: center;

    font-family: ${FONTS.family.frutigerNormal};
    font-size: ${FONTS.sizes[33]};
    letter-spacing: -1px;

    color: ${({ color }) => color || COLORS.lightBlue};
  `,
};
