import { CanvasCategoryHeadingStyled as Styled } from './canvas-category-heading.styled';

interface IProps {
  label: string;
  color?: string;
}

export const CanvasCategoryHeading = (props: IProps) => {
  const { color, label } = props;

  return (
    <Styled.ShadowWrapper>
      <Styled.FigureWrapper>
        <Styled.Content color={color}>{label}</Styled.Content>
      </Styled.FigureWrapper>
    </Styled.ShadowWrapper>
  );
};
