export const FONTS = {
  family: {
    frutigerNormal: 'FrutigerRoman',
    frutigerBold: 'FrutigerBold',
    absideSmooth: 'AbsideSmooth',
    poppinsRegular: 'PoppinsRegular',
    poppinsBold: 'PoppinsBold',
  },
  sizes: {
    [9]: '9px',
    [10]: '10px',
    [11]: '11px',
    [12]: '12px',
    [13]: '13px',
    [14]: '14px',
    [15]: '15px',
    [16]: '16px',
    [18]: '18px',
    [19]: '19px',
    [20]: '20px',
    [22]: '22px',
    [24]: '24px',
    [26]: '26px',
    [28]: '28px',
    [30]: '30px',
    [33]: '33px',
    [35]: '35px',
    [36]: '36px',
    [40]: '40px',
    [60]: '60px',
    [50]: '50px',
  },
};
