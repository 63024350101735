export const ROUTES = {
  main: '/',
  welcome: '/welcome',
  platform: '/platform',
  pdf: '/caas-quiz/pdf',
  signIn: '/sign-in',
  signUp: '/sign-up',
  resetPassword: '/reset-password',
  updatePassword: '/password',
  dashboard: '/platform/dashboard',
  profile: '/platform/profile',
  history: '/platform/history',
  invitation: '/platform/invitation',
  trainer: '/platform/trainer',
  careerFlex: '/career-flex',
  redirectAuth: '/auth',
  careerFlexAdmin: '/platform/career-flex-admin',
  careerFlexPlusAdmin: '/platform/career-flex-plus-admin',
  careerFlexPlus: '/career-flex-plus',
  careerFlexResults: '/career-flex/results',
  careerDesignGame: '/platform/career-design-game',
  careerDesignCanvas: '/career-canvas',
  careerDesignCanvasResults: '/career-canvas/results',
  careerCanvasAdmin: '/platform/career-canvas-admin',
  myCareerAdventure: '/my-career-adventure',
  manageUser: '/platform/manage-user',
  manageGroup: '/platform/manage-group',
  manageGroupSA: '/platform/manage-group-super-admin',
  manageTrainers: '/platform/manage-trainers',
  errorPage: '/error',
  editUser: '/platform/manage-user/:userId',
  editTrainer: '/platform/manage-trainers/:userId',
  deleteUserAccount: '/platform/manage-user/:userId/delete',
  deleteTrainerAccount: '/platform/manage-trainers/:userId/delete',
} as const;
